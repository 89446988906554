<template>
  <div>
    <div class="page-content py-5 pt-0">
      <!--slider-->
      <div class="chat-container">
        <div class="chat-slider-container-bg overflow-hidden">
          <div class="container text-white">
            <div
              id="chatProjectCarousel"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item overflow-hidden active">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-5 pt-5 text-center my-auto">
                        <img
                          src="@/assets/images/logo/chatonfaith.png"
                          data-animation="animated zoomInRight"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2"
                          data-animation="animated bounceInLeft"
                        >
                          الحوار المباشر
                        </h2>
                        <div
                          class="info lh-lg text-justify"
                          data-animation="animated bounceInRight"
                        >
                          هو مشروع يقوم على دعوة غير المسلمين عن طريق التواصل
                          المباشر عبر كافة وسائل التواصل الاجتماعي. يعمل المشروع
                          بأكثر من سبع لغات عالمية ويقوم على الدعوة أكثر من 10
                          دعاة متخصصين يعملون على مدار الساعة للتعريف بالإسلام
                          والرد على الشبهات.
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item1-content-->
                </div>
                <!--//carousel-item1-->
                <div class="carousel-item">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2"
                          data-animation="animated bounceInRight"
                          data-aos-delay="1000"
                        >
                          الحوار المباشر
                        </h2>
                        <div
                          class="info lh-lg text-justify"
                          data-animation="animated zoomInLeft"
                          data-aos-delay="1000"
                        >
                          يعد المشروع من أكبر المشاريع الدعوية على شبكة الإنترنت
                          حيث يمتلك هذا المشروع الرائد موقعا إلكترونيا وحسابات
                          لأغلب شبكات التواصل الاجتماعي المتاحة حاليا، يعمل
                          المشروع بسبعة لغات عالمية تعد من أوسع اللغات انتشارا
                          حول العالم.
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 pt-5 my-auto text-center">
                        <img
                          src="@/assets/images/mic.png"
                          data-animation="animated bounceIn"
                          data-aos-delay="1000"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item2-content-->
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#chatProjectCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#chatProjectCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad3" x2="100%" x1="100%" y2="0%" y1="0%">
              <stop offset="10%" stop-opacity="1" stop-color="#1a6ae1"></stop>
              <stop offset="30%" stop-opacity="1" stop-color="#7060df"></stop>
              <stop offset="70%" stop-opacity="1" stop-color="#7060df"></stop>
              <stop offset="90%" stop-opacity="1" stop-color="#1a6ae1"></stop>
            </linearGradient>
            <path
              id="sineWave"
              fill="url(#grad3)"
              fill-opacity="0.3"
              d="M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0"
            ></path>
          </defs>
          <use class="wave" href="#sineWave"></use>
          <use class="wave" x="-100%" href="#sineWave"></use>
          <use class="wave1" href="#sineWave"></use>
          <use class="wave1" x="-100%" href="#sineWave"></use>
          <use class="wave2" href="#sineWave"></use>
          <use class="wave2" x="-100%" href="#sineWave"></use>
        </svg>
      </div>
      <!--// slider-->

      <!--//about-project-->
      <div class="about-project overflow-hidden bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-12 mb-sm-2">
              <img
                src="@/assets/images/Image.png"
                alt=""
                data-aos="fade-in"
                class="rounded-30 w-100"
                srcset=""
                style="max-width: 400px !important"
              />
            </div>
            <div class="col-lg-7 col-12">
              <div
                class="about-project-mic d-none d-lg-block"
                data-aos="fade-left"
              ></div>
              <h2 class="edc-color-primary" data-aos="zoom-in">عن المشروع</h2>
              .
              <div
                class="about-project-info ms-6 text-justify"
                data-aos="fade-left"
              >
                يعد المشروع من أكبر المشاريع الدعوية على شبكة الإنترنت، يعمل
                المشروع بسبعة لغات عالمية تعد من أوسع اللغات انتشارا حول العالم
                هي اللغة الإنجليزية واللغة الإسبانية واللغة الفلبينية والسواحلية
                واليابانية والكورية والتايلاندية وأكثر من 10 دعاة متخصصون في
                محاورة كافة الأعمار والمشارب الثقافية، وما قدمه المشروع من تميز
                نوعي جعل المشروع في صدارة المشهد الثقافي والتعريفي للتعريف
                بالإسلام، كما يهتم المشروع برفع الشبهات المثارة عن الإسلام
                والمسلمين والرد عليها بالمنطق والبرهان والدليل العقلي القائم على
                الحجج والبراهين المنطقية، واتباع أفضل وأيسر الطرق العلمية
                للمحاورة والإقناع، واستخدام أحدث الوسائل والتطبيقات في سرعة
                وسهولة التواصل مع المتحاورين حول العالم وبمختلف لغات المشروع.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//about-project-->
      <!--//about-project-->
      <div class="about-project overflow-hidden py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-12">
              <div
                class="about-project-info ms-6 text-justify"
                data-aos="fade-left"
              >
                يعتمد مشروع الحوار المباشر على حسن التواصل وإيصال الأفكار
                الصحيحة التي ترسم صورة ذهنية متكاملة عند الآخر، خالية من شوائب
                الإفراط أو التفريط، عن دين الإسلام والمسلمين من خلال حوار ثقافي
                ممتع عن الأديان السائدة كلٌ بلغته وحسب ثقافته تحت مظلة البرهان
                والمنطق والدليل الصحيح وفي جو هادئ من الحوار البنّاء. يبدأ
                الحوار باختيار الضيف نفسه الراغب في التحاور ثم ينتظم بشكل مباشر
                بين المحاور والضيف عبر منصات التواصل المختلفة يجيب فيها المحاور
                على كافة الأسئلة ويفند الأفكار المخالفة المطروحة ويقدم صورة
                صحيحة عن الإسلام والمسلمين.
              </div>
            </div>
            <div class="col-lg-5 col-12 mb-sm-2 text-center">
              <img
                src="@/assets/images/logo/chatonfaith.png"
                alt=""
                data-aos="fade-in"
                class="w-50 mx-auto"
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
      <!--//about-project-->
      <!--Goals-->
      <div class="chat-goals-container bg-white py-5">
        <div class="container">
          <div class="chat-goals-box overflow-hidden row">
            <div class="chat-goals-box-right col-12 col-xl-4 mb-2 col-md-4">
              <h4
                class="
                  chat-goals-box-right-title
                  fw-bold
                  edc-color-primary
                  fs-3
                  edc-section-title
                "
              >
                أهداف المشروع
              </h4>
              <div class="text-muted fs-5 text-justify mt-2">
                نطمح أن نكون المشروع الثقافي التحاوري الأول حول العالم الذي يقدم
                الصورة المثلى عن الإسلام والمسلمين، ويقوم ببيان ما أشكل على
                المخالفين أو توضيح استفسارات المستفسرين حول العالم، ومحاربة كل
                انواع الجهل والتطرف، وعقدة الخوف من الإسلام والمسلمين حول
                العالم.
              </div>
            </div>
            <div class="chat-goals-box-left col-12 col-xl-8 col-md-8">
              <div class="container p-0">
                <div class="row p-0 overflow-hidden">
                  <!--box-->
                  <div
                    class="col-12 col-lg-6 mb-3 col-md-12"
                    data-aos="fade-up"
                  >
                    <div
                      class="
                        bg-white
                        rounded-30
                        border-1
                        py-4
                        px-3
                        d-flex
                        justify-content-start
                        align-items-center
                      "
                    >
                      <div
                        class="icon edc-gradien-podcast edc-gradien-icon m-2"
                      >
                        <i
                          class="fa fa-podcast fs-rem-5-5"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="text">
                        <h4>تعريف غير المسلمين</h4>
                        <p class="text-muted">
                          بالإسلام وفق المنهج الوسطي القويم، وبيان محاسن الإسلام
                          للعالمين
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-6 mb-3 col-md-12"
                    data-aos="fade-right"
                  >
                    <div
                      class="
                        bg-white
                        rounded-30
                        border-1
                        py-4
                        px-3
                        d-flex
                        justify-content-start
                        align-items-center
                      "
                    >
                      <div class="icon edc-gradien-ear edc-gradien-icon m-2">
                        <i
                          class="fas fa-assistive-listening-systems fs-rem-4"
                        ></i>
                      </div>
                      <div class="text">
                        <h4>القيام بواجب الدعوة</h4>
                        <p class="text-muted">
                          إلى الله تعالى بالحكمة والموعظة الحسنة.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-6 mb-3 col-md-12"
                    data-aos="fade-down"
                  >
                    <div
                      class="
                        bg-white
                        rounded-30
                        border-1
                        py-4
                        px-3
                        d-flex
                        justify-content-start
                        align-items-center
                      "
                    >
                      <div
                        class="icon edc-gradien-headphones edc-gradien-icon m-2"
                      >
                        <i
                          class="fa fa-headphones-alt fs-rem-4"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="text">
                        <h4>نشر صحيح الإسلام</h4>
                        <p class="text-muted">
                          والتعريف بأخلاقه بعدة لغات عالمية.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-6 col-md-12 mb-3"
                    data-aos="fade-right"
                  >
                    <div
                      class="
                        bg-white
                        rounded-30
                        border-1
                        py-4
                        px-3
                        d-flex
                        justify-content-start
                        align-items-center
                      "
                    >
                      <div class="icon edc-gradien-mosque edc-gradien-icon m-2">
                        <i class="fas fa-mosque fs-rem-4"></i>
                      </div>
                      <div class="text">
                        <h4>توظيف الوسائل</h4>
                        <p class="text-muted">
                          التكنولوجية الحديثة في الدعوة المباشرة إلى الله تعالى.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//Goals-->

      <!--status-->
      <div class="chat-status-container py-5">
        <div class="container">
          <h4
            class="
              chat-status-title
              edc-color-primary
              fw-bold
              fs-2
              mb-3
              edc-section-title
            "
          >
            إحصائيات الحوار
          </h4>
          <!--box one-->
          <div class="chat-status-blocks overflow-hidden row">
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fa fa-podcast fs-rem-5-5 mb-3" aria-hidden="true"></i>
                <h4 class="mb-3 fs-3">اجمالي المهتدين</h4>
                <div class="chat-status-block-count fs-2">
                  <count-up
                    v-if="status.chat_on_faith"
                    :endVal="parseInt(status.chat_on_faith.total)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box one-->
            <!--box tow-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fas fa-cloud-download-alt fs-rem-5-5 mb-3"></i>
                <h4 class="mb-3 fs-3">اجمالي الحورات</h4>
                <div class="chat-status-block-count fs-2">
                  <count-up
                    v-if="status.chat_on_faith"
                    :endVal="parseInt(status.chat_on_faith.dialogs)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box tow-->
            <!--box three-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fas fa-headphones-alt fs-rem-5-5 mb-3"></i>
                <h4 class="mb-3 fs-3">عدد الدول</h4>
                <div class="chat-status-block-count fs-2">
                  <count-up
                    v-if="status.chat_on_faith"
                    :endVal="parseInt(status.chat_on_faith.countries)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box three-->
          </div>
        </div>
      </div>
      <!--//status-->

      <!--Achievements-->
      <div class="chat-achievements-container overflow-hidden py-5">
        <div class="container">
          <h4 class="edc-color-primary fw-bold fs-2 edc-section-title">
            {{ $t("title.chat_tool") }}
          </h4>
          <div class="chat-achievments-box overflow-hidden row mt-2">
            <!-- boxOne-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  chat-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="chat-achievments-item-thumble">
                  <img
                    src="@/assets/images/chat-on-faith-dash.png"
                    class="
                      chat-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="
                    chat-achievments-item-title
                    border-1 border-bottom
                    mb-2
                  "
                >
                  <h1
                    class="
                      h4
                      py-3
                      d-block
                      color-dark
                      d-flex
                      justify-content-between
                    "
                  >
                    <span> شاشة الإحصائيات </span>
                    <div>
                      <a href="https://chatonfaith.com/dash/" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </h1>
                </div>

                <div
                  class="chat-achievments-item-details text-muted text-justify"
                >
                  تعمل هذه الشاشة على عرض جميع مخرجات المشروع على مدار الساعة
                  بشكل تلقائي، وتم عرض هذه المخرجات بصورة سهلة وشديدة الوضوح
                  تمكن المستخدم والزائر من مطالعة كافة أرقام وإحصائيات المشروع.
                </div>
              </div>
            </div>
            <!--// boxOne-->
            <!-- boxTow-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  chat-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="chat-achievments-item-thumble">
                  <img
                    src="@/assets/images/chat-on-faith-tools.png"
                    class="
                      chat-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="
                    chat-achievments-item-title
                    border-1 border-bottom
                    mb-2
                  "
                >
                  <h1 class="h4 py-3 d-block color-dark">منصات الحوار</h1>
                </div>

                <div
                  class="chat-achievments-item-details text-muted text-justify"
                >
                  تستخدم اللجنة أشهر منصات التواصل ويتضمن ذلك التواصل مع الزوار
                  عبر الواتساب والفيسبوك وتويتر والبريد الإلكتروني وغيرها من
                  وسائل التواصل، كما تفعل اللجنة خدمة التواصل عبر المواقع
                  الدعوية من خلال خدمة ربط إلكتروني خاصة.
                </div>
              </div>
            </div>
            <!--// boxTow-->
            <!-- boxThree-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  chat-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="chat-achievments-item-thumble">
                  <img
                    src="@/assets/images/chat-on-faith-adv.png"
                    class="
                      chat-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="
                    chat-achievments-item-title
                    border-1 border-bottom
                    mb-2
                  "
                >
                  <h1 class="h4 py-3 d-block color-dark">
                    الإعلانات الإلكترونية
                  </h1>
                </div>

                <div
                  class="chat-achievments-item-details text-muted text-justify"
                >
                  تقوم اللجنة بعمل تسويق إلكتروني يعمل على مدار الساعة، ويستهدف
                  الوصول إلى الجمهور الأكثر اهتماماً بالمعرفة عن الإسلام وقضايا
                  الأديان المشتركة بهدف عمل حوار ثقافي بناء وعدم إضاعة وقت
                  الدعاة مع جمهور لا يكترث بهذا النوع من الموضوعات.
                </div>
              </div>
            </div>
            <!--// boxThree-->
          </div>
        </div>
      </div>
      <!--//Achievements-->
      <!--donate-->
      <div class="chat-donate-container overflow-hidden bg-white py-5">
        <div
          class="container d-flex justify-content-between align-items-center"
        >
          <div class="chat-donate-title">
            <h4 class="py-2 fs-2">{{ $t("label.donate_to_project") }}</h4>
            <small class="text-muted fs-6">كفالة داعية</small>
          </div>
          <div class="chat-donate-button">
            <router-link
              :to="{ path: '/products/الحوار-المباشر-للتعريف-بالإسلام/' }"
              class="btn edc-btn-primary text-white p-2 px-5 rounded-20 fs-md-4"
              data-aos="zoom-in"
            >
              {{ $t("btn.donate_now") }}
              <i class="fas fa-hand-holding-usd"></i>
            </router-link>
          </div>
        </div>
      </div>
      <!--//donate-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ogImage from "@/assets/images/logo/chatonfaith.png";
export default {
  name: "ChatOnFaith",
  computed: {
    ...mapGetters("project_status", ["status"]),
  },
  methods: {
    ...mapActions("project_status", ["getStatusValue"]),
  },
  created() {
    this.getStatusValue();
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.chat_on_faith") + " | %s",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("title.chat_on_faith"),
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        {
          vmid: "og:image",
          property: "og:image",
          content: window.location.origin + ogImage || "",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.wave {
  animation: wave 8s linear infinite;
}
.wave1 {
  animation: wave1 10s linear infinite;
}
.wave2 {
  animation: wave2 12s linear infinite;
}
@keyframes wave {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes wave1 {
  0% {
    transform: scaleY(1.2) translateX(0%);
  }
  100% {
    transform: scaleY(1.2) translateX(100%);
  }
}
@keyframes wave2 {
  0% {
    transform: scaleY(0.8) translateX(0%);
  }
  100% {
    transform: scaleY(0.8) translateX(100%);
  }
}
</style>